
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { Col,  Button } from "reactstrap";
import Loader from "react-loaders";

import config from "../../config.json";
import logo from '../../assets/utils/images/e-proeri.jpg'
import background from '../Account/assets/Foto1.jpg'

class NotifConfirm extends Component {

    constructor(props) {
      super(props);
      this.navigate = this.navigate.bind(this);
      this.state = {
        activationMessage : "",
        loading : true,
        ok: false
    };
}


  componentDidMount(){

    const activateAccountLink = process.env.REACT_APP_API + "/AuthManual/ConfirmarNotificacion";
    
    const params = new URLSearchParams(window.location.search);


        const token = params.get('token');
        const notifId = params.get('notifId');
        const suppId = params.get('suppId');

        const body = {
            NotificacionId :Number(notifId),
            SupplierId : Number(suppId),
            Token : token
        }

        const values = JSON.stringify(body);
          
        this.setState({loading: false});
    
        const headers= {
              "Access-Control-Allow-Origin": true,
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }

          console.log(body);
        if(token){
            axios.post(activateAccountLink, values,{
              headers
            })
            .then(resp=>{
    
                if(resp.data.operacionConExito){
                    this.setState({activationMessage : "Su confirmación fue enviada exitosamente", loading: false, ok: true});
                }else{
                    this.setState({activationMessage : resp.data.error || "Error", loading: false});
                }
    
            }).catch(err=>{
                this.setState({activationMessage : "Error de petición o token inválido", loading: false});
            });
        }

  }

  navigate(){
    //console.log(this.props);
    //this.props.history.push("/login");
    window.location.href = '/login';
  }

  render (){


    /*
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      background-color: #A7FCA2;
    */

    return <Fragment>
    <div  style={{backgroundImage : `url('${background}')`,  
                        backgroundSize: "cover", // La imagen cubrirá el contenedor sin deformarse
                        backgroundPosition: "center", // Centra la imagen en el contenedor
                        backgroundRepeat: "no-repeat", // Evita que la imagen se repita
                        width: "100vw", // Ancho igual al ancho de la ventana
                        height: "100vh",}}
                        >
      <div className="d-flex h-100 justify-content-center align-items-center">
        <Col md="6" className="mx-auto app-login-box">
         
          <div className="modal-dialog w-100">
            <div className="modal-content">
              <div className="modal-header">
                  <div style={{width: '100%',display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center'}}>
                    <img src={logo} style={{width: 200, height: 70}}></img>
                  </div>
              </div>
              <div className="modal-body">
        
                     {this.state.loading ?  <div className="loader-wrapper d-flex justify-content-center align-items-center">
                        <Loader type="ball-scale-multiple" />
                      </div> : this.state.ok ? <div>
                          <span style={{textAlign: 'center'}}><b>¡Muchas gracias!</b></span>
                          <br/>
                          <span style={{textAlign: 'center'}}>
                          Tu confirmación ha sido enviada exitosamente.
                          </span>
                      </div> : <h1 className="h1">{this.state.activationMessage}</h1>}

                    </div>
                        <div className="modal-footer clearfix">
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </Fragment>
  }

};

export default withRouter(NotifConfirm);