import React, { Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";
import Loader from "react-loaders";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";


//Pages
import Login from '../../Pages/Account/Login';
import AppMain from "../../Layout/AppMain";
import Activation from "../../Pages/Account/Activation";
import PasswordReset from "../../Pages/Account/PasswordReset";
import config from "../../config.json";
import NotifConfirm from "../../Pages/Account/NotifConfirm";

//Lazy import => Recomended
const InitialPage = lazy(()=>import("../../Pages/PublicPage/Initial"));
const LicitacionesPage =   lazy(()=>import("../../Pages/PublicPage/LicitacionesPage"));
const SoportePage =   lazy(()=>import("../../Pages/PublicPage/Soporte"));
const TableroPage = lazy(()=>import("../../Pages/PublicPage/Tablero"));
const CalendarPage = lazy(()=>import("../../Pages/PublicPage/CalendarioActividades"));


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
  }


  componentDidMount() {
    
     const url = process.env.REACT_APP_API + config.API_PUBLIC_PROCUREMENT + '/verifyStatusProccessId'; 
     axios.get(url).then(res=>{
        if(res.data.value === 1){
           console.log('Syncronizing');
           console.log(res.data);
        }else{
          console.log('Error');
        }
     })
  }

  render() {

    const loaderContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Para que ocupe toda la altura de la ventana
      width: '100vw',  // Para que ocupe toda la anchura de la ventana
    };

    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

   

    //console.log('autenticado: '+this.props.auth.isAuthenticated)
    let content = !!this.props.auth.isAuthenticated ?
    (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}>
              <AppMain queryIdProcedurement = {this.props.location.search} /> 
            </div>
          </Fragment>
        )}
      />
    ):
    window.location.pathname === '/password_reset' ? <PasswordReset/>:
    (window.location.pathname === '/login' ? <Login/> :
        (window.location.pathname === '/activation_account') ? <Activation/> : 
        window.location.pathname === '/confirmarNotificacion' ? <NotifConfirm/> :
        <div>  

          <Suspense fallback={<div style={loaderContainerStyle}>
                          <Loader type="ball-clip-rotate-multiple" active />
                          <h1>Cargando...</h1>
                        </div>}
          >   
              <Switch>   
                  <Route exact path="/" component={InitialPage}/>       
                  <Route path="/licitaciones" component={LicitacionesPage}/>
                  <Route path="/soporte" component={SoportePage}/>
                  <Route path="/tablero" component={TableroPage}/>
                  <Route path="/calendar" component={CalendarPage}/>
                  <Route path="/login" component={Login}/>
                  <Redirect to="/login"/>
              </Switch>   
          </Suspense>     
              
          {/* Add more Route components as needed */}
        </div>
        ) 

return (
  <div>
      {content}
  </div>
);

  }
}


const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  auth: state.auth,
});

export default withRouter(connect(mapStateToProp)(Main));
